import React from 'react'
import './SearchBar.css';
import { BiSearchAlt } from 'react-icons/bi'
import { useHistory } from 'react-router-dom';
import { maindata, aboutdata,eventsdata,workshopsdata,lecturedata,spotdata,advisor_data,management_data,executives_data } from '../../maindata';

function SearchBar() {
    const [search, setSearch] = React.useState('')
    const history = useHistory();
    const searchClick=()=>{
        if(!search.length)return;
        const searchReplaced = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
        if(Object.values(aboutdata)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/aboutus')
        }
        else if(Object.values(eventsdata)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/events')
        }
        else if(Object.values(workshopsdata)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/events/workshops')
        }
        else if(Object.values(lecturedata)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/events/lecture')
        }
        else if(Object.values(spotdata)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/events/on_the_spot')
        }
        else if(maindata.intro_info?.search(searchReplaced)!=-1 || maindata.AUV_Club?.search(searchReplaced)!=-1 || maindata.AUV_Line_Follower?.search(searchReplaced)!=-1){
            history.push('/')
        }
        else if(Object.values(maindata.HPVC)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/participation/HPVC')
        }
        else if(Object.values(maindata.SAUVC)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/participation/SAUVC')
        }
        else if(Object.values(maindata.SDC)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/participation/SDC')
        }
        else if(Object.values(maindata.TCTD)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/participation/TCTD')
        }
        else if(Object.values(maindata.cogni)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/participation/Cognizance')
        }
        else if(Object.values(maindata.IRC)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/participation/IRC')
        }
        else if(Object.values(maindata.Shrishti)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/participation/Shrishti')
        }
        else if(Object.values(maindata.Chanakya)?.join(' ')?.search(searchReplaced)!=-1){
            history.push('/participation')
        }
        else if(advisor_data.map((item)=>item.name).join(' ')?.search(searchReplaced)!=-1 || management_data.map((item)=>item.name).join(' ')?.search(searchReplaced)!=-1 || executives_data.map((item)=>item.name).join(' ')?.search(searchReplaced)!=-1){
            history.push('/team')
        }
        setSearch('');
    }
    return (
        <React.Fragment>
        <div className="search_prop">
            <input onKeyDown={(e)=>{e.key === 'Enter' && searchClick()}} placeholder="Search" value={search} onChange={(e)=>setSearch(e.target.value)} type="text" className="search_box"/>
            <button className="search_button" onClick={searchClick}>
                <BiSearchAlt size={20}/>
                <p>Search</p>
            </button>
        </div>
        <div>
            <hr className="search_divide"/>
        </div>
        </React.Fragment>
    )
}

export default SearchBar
