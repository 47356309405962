import React from 'react'
import '../../Events/events.css';
import gears from '../../../assets/icons/gears.svg';
import { maindata } from '../../../maindata';

function Cognizance() {
    return (
        <React.Fragment>
        <div className="about_style">
            <h1>Cognizance</h1>

            <img className="event_gear_prop" src={gears} />

            <p className="main_content">{ maindata['cogni']['about'] }</p>

            <h2>{ maindata['cogni']['2019_head'] }</h2>
            <div className="event_content">
            <div className="left_content">
            <p className="main_content">{ maindata['cogni']['2019_info'] }</p>
            <p className="main_content">{ maindata['cogni']['2019_info_2'] }</p>
            </div>
            <div className="right_content">

            </div>
            </div><br />

            <h2>{ maindata['cogni']['2018_head'] }</h2>
            <div className="event_content">
            <div className="left_content">
            <p className="main_content">{ maindata['cogni']['2018_info'] }</p>
            </div>
            <div className="right_content">
                
            </div>
            </div><br />

            <h2>{ maindata['cogni']['2018_2_head'] }</h2>
            <div className="event_content">
            <div className="left_content">
            <p className="main_content">{ maindata['cogni']['2018_2_info'] }</p>
            </div>
            <div className="right_content">
                
            </div>
            </div><br />

        </div>
        </React.Fragment>
    )
}

export default Cognizance