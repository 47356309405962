import React, { Component } from 'react'
import './events.css';
import sample from '../../assets/images/sample.png';
import gears from '../../assets/icons/gears.svg';
import { lecturedata,eventsdata } from '../../maindata';
import lec1 from '../../assets/images/lec1.jpg';
import lec2 from '../../assets/images/lec2.png';



const Lecture = (props) => {
    return (
        <React.Fragment>
        <div className="about_style">
            <h1>{lecturedata['heading_1']}</h1>
            <img className="event_gear_prop" src={gears} />
            <p className="main_content">{eventsdata['para_1']}</p>
            
            <div className='irc_image'>
                <img class="irc_2017" src={lec1} alt=""/>
                <img class="irc_2017" src={lec2} alt=""/>
            </div>
        </div>
        </React.Fragment>
    )
}

export default Lecture