import React from 'react'
import './Gallery.css'
import gears from '../../assets/icons/gears.svg';
import "swiper/css/swiper.css"
import Swiper from 'react-id-swiper'
import I_1_1 from '../../assets/images/gallery/ASME_Club_Day_2019/1.jpg'
import I_1_2 from '../../assets/images/gallery/ASME_Club_Day_2019/2.jpg'
import I_1_3 from '../../assets/images/gallery/ASME_Club_Day_2019/3.jpg'
import I_1_4 from '../../assets/images/gallery/ASME_Club_Day_2019/4.jpg'
import I_1_5 from '../../assets/images/gallery/ASME_Club_Day_2019/5.jpg'
import I_1_6 from '../../assets/images/gallery/ASME_Club_Day_2019/6.jpg'
import I_1_7 from '../../assets/images/gallery/ASME_Club_Day_2019/7.jpg'
import I_1_8 from '../../assets/images/gallery/ASME_Club_Day_2019/8.jpg'
import I_1_9 from '../../assets/images/gallery/ASME_Club_Day_2019/9.jpg'
import I_1_10 from '../../assets/images/gallery/ASME_Club_Day_2019/10.jpg'
import I_2_1 from '../../assets/images/gallery/ASME_E-Fest_2019/1.jpg'
import I_2_2 from '../../assets/images/gallery/ASME_E-Fest_2019/2.jpg'
import I_2_3 from '../../assets/images/gallery/ASME_E-Fest_2019/3.jpg'
import I_2_4 from '../../assets/images/gallery/ASME_E-Fest_2019/4.jpg'
import I_2_5 from '../../assets/images/gallery/ASME_E-Fest_2019/5.jpg'
import I_2_7 from '../../assets/images/gallery/ASME_E-Fest_2019/7.jpg'
import I_2_8 from '../../assets/images/gallery/ASME_E-Fest_2019/8.jpg'
import I_2_9 from '../../assets/images/gallery/ASME_E-Fest_2019/9.jpg'
import I_2_10 from '../../assets/images/gallery/ASME_E-Fest_2019/10.jpg'
import I_3_1 from '../../assets/images/gallery/ASME_E-Fest_2020/1.jpg'
import I_3_2 from '../../assets/images/gallery/ASME_E-Fest_2020/2.jpg'
import I_3_3 from '../../assets/images/gallery/ASME_E-Fest_2020/3.jpg'
import I_3_4 from '../../assets/images/gallery/ASME_E-Fest_2020/4.jpg'
import I_3_5 from '../../assets/images/gallery/ASME_E-Fest_2020/5.jpg'
import I_3_6 from '../../assets/images/gallery/ASME_E-Fest_2020/6.jpg'
import I_3_7 from '../../assets/images/gallery/ASME_E-Fest_2020/7.jpg'
import I_3_8 from '../../assets/images/gallery/ASME_E-Fest_2020/8.jpg'
import I_3_9 from '../../assets/images/gallery/ASME_E-Fest_2020/9.jpg'
import I_3_10 from '../../assets/images/gallery/ASME_E-Fest_2020/10.jpg'
import I_4_1 from '../../assets/images/gallery/HPVC_Unveiling_Ceremony_2019/1.jpg'
import I_4_2 from '../../assets/images/gallery/HPVC_Unveiling_Ceremony_2019/2.jpg'
import I_4_3 from '../../assets/images/gallery/HPVC_Unveiling_Ceremony_2019/3.jpg'
import I_4_4 from '../../assets/images/gallery/HPVC_Unveiling_Ceremony_2019/4.jpg'
import I_4_5 from '../../assets/images/gallery/HPVC_Unveiling_Ceremony_2019/5.jpg'
import I_4_6 from '../../assets/images/gallery/HPVC_Unveiling_Ceremony_2019/6.jpg'
import I_4_7 from '../../assets/images/gallery/HPVC_Unveiling_Ceremony_2019/7.jpg'
import I_4_8 from '../../assets/images/gallery/HPVC_Unveiling_Ceremony_2019/8.jpg'
import I_4_9 from '../../assets/images/gallery/HPVC_Unveiling_Ceremony_2019/9.jpg'
import I_4_10 from '../../assets/images/gallery/HPVC_Unveiling_Ceremony_2019/10.jpg'
import I_5_1 from '../../assets/images/gallery/Inter-IIT_Tech_Meet_2018/1.jpg'
import I_5_2 from '../../assets/images/gallery/Inter-IIT_Tech_Meet_2018/2.jpg'
import I_5_3 from '../../assets/images/gallery/Inter-IIT_Tech_Meet_2018/3.jpg'
import I_5_4 from '../../assets/images/gallery/Inter-IIT_Tech_Meet_2018/4.jpg'
import I_5_5 from '../../assets/images/gallery/Inter-IIT_Tech_Meet_2018/5.jpg'
import I_5_7 from '../../assets/images/gallery/Inter-IIT_Tech_Meet_2018/7.jpg'
import I_5_8 from '../../assets/images/gallery/Inter-IIT_Tech_Meet_2018/8.jpg'
import I_5_9 from '../../assets/images/gallery/Inter-IIT_Tech_Meet_2018/9.jpg'
import I_6_1 from '../../assets/images/gallery/Photoshoot_2019/1.jpg'
import I_6_2 from '../../assets/images/gallery/Photoshoot_2019/2.jpg'
import I_6_3 from '../../assets/images/gallery/Photoshoot_2019/3.jpg'
import I_6_4 from '../../assets/images/gallery/Photoshoot_2019/4.jpg'
import I_6_5 from '../../assets/images/gallery/Photoshoot_2019/5.jpg'
import I_6_6 from '../../assets/images/gallery/Photoshoot_2019/6.jpg'
import I_6_7 from '../../assets/images/gallery/Photoshoot_2019/7.jpg'
import I_6_8 from '../../assets/images/gallery/Photoshoot_2019/8.jpg'
import I_6_9 from '../../assets/images/gallery/Photoshoot_2019/9.jpg'
import I_6_10 from '../../assets/images/gallery/Photoshoot_2019/10.jpg'
import I_7_1 from '../../assets/images/gallery/Rishikesh_Trip_2019/1.jpg'
import I_7_2 from '../../assets/images/gallery/Rishikesh_Trip_2019/2.jpg'
import I_7_3 from '../../assets/images/gallery/Rishikesh_Trip_2019/3.jpg'
import I_7_4 from '../../assets/images/gallery/Rishikesh_Trip_2019/4.jpg'
import I_7_5 from '../../assets/images/gallery/Rishikesh_Trip_2019/5.jpg'
import I_7_6 from '../../assets/images/gallery/Rishikesh_Trip_2019/6.jpg'
import I_7_7 from '../../assets/images/gallery/Rishikesh_Trip_2019/7.jpg'
import I_7_8 from '../../assets/images/gallery/Rishikesh_Trip_2019/8.jpg'
import I_8_1 from '../../assets/images/gallery/Srishti_2019/1.jpg'
import I_8_2 from '../../assets/images/gallery/Srishti_2019/2.jpg'
import I_8_3 from '../../assets/images/gallery/Srishti_2019/3.jpg'
import I_8_4 from '../../assets/images/gallery/Srishti_2019/4.jpg'
import I_8_5 from '../../assets/images/gallery/Srishti_2019/5.jpg'
import I_8_6 from '../../assets/images/gallery/Srishti_2019/6.jpg'
import I_8_7 from '../../assets/images/gallery/Srishti_2019/7.jpg'
import I_8_8 from '../../assets/images/gallery/Srishti_2019/8.jpg'
import I_8_9 from '../../assets/images/gallery/Srishti_2019/9.jpg'
import I_8_10 from '../../assets/images/gallery/Srishti_2019/10.jpg'
import I_9_1 from '../../assets/images/gallery/Workshops_2019/1.jpg'
import I_9_2 from '../../assets/images/gallery/Workshops_2019/2.jpg'
import I_9_3 from '../../assets/images/gallery/Workshops_2019/3.jpg'
import I_9_4 from '../../assets/images/gallery/Workshops_2019/4.jpg'
import I_9_5 from '../../assets/images/gallery/Workshops_2019/5.jpg'
import I_9_6 from '../../assets/images/gallery/Workshops_2019/6.jpg'
import I_9_7 from '../../assets/images/gallery/Workshops_2019/7.jpg'
import I_9_8 from '../../assets/images/gallery/Workshops_2019/8.jpg'

const Gallery = () => {
    const params = {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: 'auto',
        loopFillGroupWithBlank: true,
        spaceBetween:0,
        // loop:true,
        // autoplay: {
        //     delay: 2500,
        //     disableOnInteraction: false
        // },
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 5,
                spaceBetween: 50,
            },
        },
      }
  return (
    <div className="about_style gallery_parent_swiper">
        <h1>Gallery</h1>
        <img className="gear_prop gallery_gear" src={gears} />
        <h1 className="gallery_heading">ASME Club Day 2019</h1>
        <Swiper {...params}>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_1_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_1_2}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_1_3}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_1_4}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_1_5}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_1_6}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_1_7}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_1_8}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_1_9}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_1_10}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_1_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_1_2}  alt="" />
                </div>
            </div>
        </Swiper>
        <h1 className="gallery_heading">ASME E-Fest 2019</h1>
        <Swiper {...params}>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_2_1}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_2_2}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_2_3}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_2_4}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_2_5}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_2_7}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_2_8}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_2_9}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_2_10}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_2_1}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_2_2}  alt="" />
                </div>
            </div>
        </Swiper>
        <h1 className="gallery_heading">ASME E-Fest 2020</h1>
        <Swiper {...params}>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_3_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_3_2}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_3_3}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_3_4}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_3_5}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_3_6}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_3_7}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_3_8}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_3_9}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_3_10}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_3_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_3_2}  alt="" />
                </div>
            </div>
        </Swiper>
        <h1 className="gallery_heading">HPVC Unveiling Ceremony 2019</h1>
        <Swiper {...params}>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_4_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_4_2}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_4_3}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_4_4}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_4_5}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_4_6}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_4_7}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_4_8}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_4_9}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_4_10}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_4_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_4_2}  alt="" />
                </div>
            </div>
        </Swiper>
        <h1 className="gallery_heading">Inter-IIT Tech Meet 2018</h1>
        <Swiper {...params}>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_5_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_5_2}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_5_3}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_5_4}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_5_5}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_5_7}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_5_8}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_5_9}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_5_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_5_2}  alt="" />
                </div>
            </div>
        </Swiper>
        <h1 className="gallery_heading">Photoshoot 2019</h1>
        <Swiper {...params}>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_6_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_6_2}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_6_3}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_6_4}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_6_5}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_6_6}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_6_7}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_6_8}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_6_9}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_6_10}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_6_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_6_2}  alt="" />
                </div>
            </div>
        </Swiper>
        <h1 className="gallery_heading">Rishikesh Trip 2019</h1>
        <Swiper {...params}>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_7_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_7_2}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_7_3}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_7_4}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_7_5}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_7_6}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_7_7}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_7_8}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_7_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_7_2}  alt="" />
                </div>
            </div>
        </Swiper>
        <h1 className="gallery_heading">Srishti 2019</h1>
        <Swiper {...params}>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_8_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_8_2}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_8_3}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_8_4}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_8_5}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_8_6}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_8_7}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_8_8}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_8_9}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_8_10}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_8_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_8_2}  alt="" />
                </div>
            </div>
        </Swiper>
        <h1 className="gallery_heading">Workshops 2019</h1>
        <Swiper {...params}>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_9_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_9_2}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_9_3}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_9_4}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_9_5}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_9_6}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_9_7}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_9_8}  alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_9_1} alt="" />
                </div>
            </div>
            <div className="gallery_card">
                <div className="gallery_card_img_p">
                    <img src={I_9_2}  alt="" />
                </div>
            </div>
        </Swiper>
    </div>
  )
}

export default Gallery