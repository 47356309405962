import React, { Component } from 'react'
import './events.css';
import sample from '../../assets/images/sample.png';
import gears from '../../assets/icons/gears.svg';
import { workshopsdata } from '../../maindata';
import work1 from '../../assets/images/work1.jpg';
import work2 from '../../assets/images/work2.png';



const Workshops = (props) => {
    return (
        <React.Fragment>
        <div className="about_style">

            <h1>{workshopsdata['heading_1']}</h1>

            <img className="event_gear_prop" src={gears} />

            <h2>{workshopsdata['sub_heading_1']}</h2>
            <div className="event_content">
            <div className="left_content">
            <p className="main_content">{workshopsdata['para_1_1']}</p>
            {/* <div className="card">
            <p className="card_head">Main Idea/ Concept</p>
            <p className="card_content">
            The ASME Student Chapter at IIT Roorkee is an undergraduate club comprised of students majoring in various fields of Engineering. Our mission is to promote and enhance technical competency and professional well-being
            </p>
            </div> */}
            <p className="main_content">{workshopsdata['para_1_2']}</p>
            </div>
            {/* <div className="right_content event_right_content"> */}

                <img class="event_prop" src={work1} alt=""/>

            {/* </div> */}
            </div><br />

            <h2>{workshopsdata['sub_heading_2']}</h2>
            <div className="event_content">
            <div className="left_content">
            <p className="main_content">{workshopsdata['para_2_1']}</p>
            {/* <div className="card">
            <p className="card_head">Main Idea/ Concept</p>
            <p className="card_content">
            The ASME Student Chapter at IIT Roorkee is an undergraduate club comprised of students majoring in various fields of Engineering. Our mission is to promote and enhance technical competency and professional well-being
            </p>
            </div> */}
            {/* <p className="content">{workshopsdata['para_1_2']}</p> */}
            </div>
                <img class="event_prop" src={work2} alt=""/>
            </div><br />

            <h2>{workshopsdata['sub_heading_3']}</h2>
            <div className="event_content">
            <div className="left_content">
            <p className="main_content">{workshopsdata['para_3_1']}</p>
            </div>
            </div><br />

            <h2>{workshopsdata['sub_heading_4']}</h2>
            <div className="event_content">
            <div className="left_content">
            <p className="main_content">{workshopsdata['para_4_1']}</p>
            </div>
            </div>

        </div>
        </React.Fragment>
    )
}

export default Workshops