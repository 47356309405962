import React from 'react'
import './MemberCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faWeebly } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons'


const FacultyCard = (props) => {
    return (
        <>
            <div className={`${props.flexDir ? "card_fd_fix " : ""}memb_car_card`}>
                <div className="memb_car_image">
                    <hr className="memb_car_line"></hr>
                    <img src={props.image}>
                    </img>
                    <hr></hr>
                </div>

                <div className="memb_car_content" style={{height: '120px'}}>
                    <h2>{props.name}</h2>
                    <h3>{props.year}</h3>
                    <h3>{props.position}</h3>

                    <div className="memb_car_icons_p" style={{height: '100px'}}>
                        <span className="memb_car_icons">
                            <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${props.fb_link}`}><FontAwesomeIcon icon={faEnvelope} /></a>
                        </span>
                        <span className="memb_car_icons">
                            <a href={props.ig_link} target="_blank"><FontAwesomeIcon icon={faGlobe} /></a>
                        </span>
                    </div>
                </div>

            </div>


        </>
    )
}

export default FacultyCard
