import React from "react";
import "./AboutUs.css";
import sample from "../../assets/images/sample.png";
import gears from "../../assets/icons/gears.svg";
import divider from "../../assets/icons/divider.svg";
import { aboutdata } from "../../maindata";
import logo from "../../assets/images/asmePage_logo.png";

function AboutUs() {
  return (
    <React.Fragment>
      <div className="about_style">
        <h1>About Us</h1>

        <img className="gear_prop" src={gears} />
        <h2>{aboutdata["heading_1"]}</h2>
        <img class="sample_prop" src={logo} alt="" />

        <p className="main_content">{aboutdata["para_1"]}</p>
        <p className="main_content">{aboutdata["para_2"]}</p>
        <p className="main_content">{aboutdata["para_3"]}</p>
        <p className="main_content">{aboutdata["para_4"]}</p>
        <p className="main_content">{aboutdata["para_5"]}</p>

        <img className="divider first" src={divider} />

        <h2>{aboutdata["heading_2"]}</h2>

        <p className="main_content">{aboutdata["para_6"]}</p>
        <p className="main_content">{aboutdata["para_7"]}</p>

        <img className="divider second" src={divider} />
      </div>
    </React.Fragment>
  );
}

export default AboutUs;
