import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import AboutUs from "./components/About";
import Workshops from "./components/Events/workshop";
import Lecture from "./components/Events/lecture";
import Spot from "./components/Events/spot";
import EventPage from "./components/EventsPage";
import ParticipationPage from "./components/Participation";
import Team from "./components/Team";
import SearchBar from "./components/SearchBar";
import HPVC from "./components/Participation_pages/HPVC";
import SAUVC from "./components/Participation_pages/SAUVC";
import SDC from "./components/Participation_pages/SDC";
import TCTD from "./components/Participation_pages/TCTD";
import Cognizance from "./components/Participation_pages/Cognizance";
import IRC from "./components/Participation_pages/IRC";
import Shrishti from "./components/Participation_pages/Shrishti";
import Blogs from "./components/Blogs";
import Footer from "./components/Footer/Footer";
import Resources from "./components/Resources";
import Gallery from "./components/Gallery";
import ContactUs from "./components/ContactUs/ContactUs";

function App() {
  return (
    <BrowserRouter>
      <React.Fragment>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route exact path="/events/workshops">
            <Workshops />
          </Route>
          <Route exact path="/events/lecture">
            <Lecture />
          </Route>
          <Route exact path="/events/on_the_spot">
            <Spot />
          </Route>
          <Route exact path="/events">
            <EventPage />
          </Route>
          <Route exact path="/participation/HPVC">
            <HPVC />
          </Route>
          <Route exact path="/participation/SAUVC">
            <SAUVC />
          </Route>
          <Route exact path="/participation/SDC">
            <SDC />
          </Route>
          <Route exact path="/participation/TCTD">
            <TCTD />
          </Route>
          <Route exact path="/participation/Cognizance">
            <Cognizance />
          </Route>
          <Route exact path="/participation/IRC">
            <IRC />
          </Route>
          <Route exact path="/participation/Shrishti">
            <Shrishti />
          </Route>
          <Route exact path="/participation">
            <ParticipationPage />
          </Route>
          <Route exact path="/resources">
            <Resources />
          </Route>
          <Route exact path="/aboutus">
            <AboutUs />
          </Route>
          <Route exact path="/blogs">
            <Blogs />
          </Route>
          <Route exact path="/team">
            <Team />
          </Route>
          <Route exact path="/gallery">
            <Gallery />
          </Route>
          <Route exact path="/contact">
            <ContactUs />
          </Route>
          <Route exact path="/">
            <LandingPage />
          </Route>
        </Switch>
        <SearchBar />
        <Footer />
      </React.Fragment>
    </BrowserRouter>
  );
}

export default App;
