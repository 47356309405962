import React from "react";
import classes from "./Footer.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SiMedium } from "react-icons/si";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import arrow from "../../assets/icons/arrow.svg";

const Footer = () => {
  const backtotop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={classes.footer}>
      <div className={classes.contact}>
        <h1>Want to know more?</h1>
        <Link to="/contact" className={classes.contactBtn}>
          Contact Us
        </Link>
      </div>
      <div className={classes.icons4} id="contact_footer">
        <span className={classes.icon1}>
          <a href="https://www.instagram.com/asmeiitr/" target="_blank">
            <FontAwesomeIcon
              icon={faInstagram}
              style={{
                color: "white",
              }}
            />
          </a>
        </span>
        <span className={classes.icon1}>
          <a href="https://www.facebook.com/asmeiitr" target="_blank">
            <FontAwesomeIcon icon={faFacebook} style={{ color: "white" }} />
          </a>
        </span>
        <span className={classes.mediumIcon}>
          <a href="https://www.medium.com/@asmeiitr" target="_blank">
            <SiMedium />
          </a>
        </span>
        <span className={classes.icon1}>
          <a href="https://in.linkedin.com/company/asmeiitr" target="_blank">
            <FontAwesomeIcon icon={faLinkedin} style={{ color: "white" }} />
          </a>
        </span>
      </div>
      <div className={classes.copyright}>
        <p>@ 2022 ASME, IIT Roorkee. All Rights reserved</p>
      </div>
      <img
        className={classes.backtotop}
        onClick={backtotop}
        src={arrow}
        alt="back to top"
      />
    </div>
  );
};

export default Footer;
