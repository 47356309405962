import React, { useEffect, useState } from 'react'
import './blogs.css';
import gears from '../../assets/icons/gears.svg';
import { MdKeyboardArrowDown } from 'react-icons/md';

const Blogs = () => {

    const [blog, setBlog] = useState([]);

    const getBlogs = async () => {
        const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40asmeiitr');
        const data = await response.json();
        setBlog(data['items']);
    }

    useEffect(() => {
        getBlogs();
    }, []);

    return (
        <React.Fragment>
        <div className="about_style">
            <h1>Blogs</h1>
            <img className="gear_prop blogs" src={gears} />

            {
                blog.map((curr) => {

                    let date = JSON.stringify(curr.pubDate);
                    let year = date.substring(1, 5);
                    let month = date.substring(6, 8);
                    let num = date.substring(9, 11);
                    if(month==="01") month = "January";
                    if(month==="02") month = "February";
                    if(month==="03") month = "March";
                    if(month==="04") month = "April";
                    if(month==="05") month = "May";
                    if(month==="06") month = "June";
                    if(month==="07") month = "July";
                    if(month==="08") month = "August";
                    if(month==="09") month = "September";
                    if(month==="10") month = "October";
                    if(month==="11") month = "November";
                    if(month==="12") month = "December";

                    let main_info = JSON.stringify(curr.description);
                    let pos1 = main_info.search("<p>");
                    let pos2 = main_info.search("</p>");
                    let intro_info = main_info.substring(pos1+3, pos2);
                    let pos3 = intro_info.search("<br>");
                    if(pos3>0) intro_info = intro_info.substring(0, pos3);
                    let info = intro_info.substring(0, 300);

                    return (
                        <div className="blog_card">
                            <div><img class="blog_image" src={ curr.thumbnail } alt=""/></div>
                            <div className="blog_content">
                                <img class="blog_image mobile_blog_img" src={ curr.thumbnail } alt=""/>
                                <div className="blog_box"></div>
                                <div className="blog_post">
                                    <p className="post">POST</p>
                                    <a href={ curr.guid } target="_blank" rel="noopener noreferrer" className="blog_link">
                                        <p className="blog_title">{ curr.title }</p>
                                    </a>
                                    <p className="blog_date">Published On <span style={{color: "#0056A5"}}>{ month } { num }, { year }</span></p>
                                    <p className="blog_desc">{ info }...</p>
                                    <a href={ curr.guid } target="_blank" rel="noopener noreferrer" className="blog_link">
                                        <MdKeyboardArrowDown />
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            <a className="more_blogs" href="https://asmeiitr.medium.com/" target="_blank" rel="noopener noreferrer">MORE BLOGS &gt;&gt;</a>
            
        </div>
        </React.Fragment>
    )
}

export default Blogs
