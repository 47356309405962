import React from 'react'
import './HomePage.css';
import { MdKeyboardArrowDown } from 'react-icons/md';
import home_img from '../../assets/images/home_img.png';
import HPVC from '../../assets/images/HPVC.png';
import AUV from '../../assets/images/AUV.jpeg';
import scroll from '../../assets/icons/scroll.svg';
import { maindata } from '../../maindata.js';
import { NavLink } from 'react-router-dom';

function Events() {
    return (
        <React.Fragment>
        <div className="home_banner">
            <div>
                <div className="left_banner">
                    <p className="main_head">ASME</p>
                    <p className="sub_head">IIT Roorkee Student Section</p> <br/><br/>
                    <p className="banner_content">{ maindata['intro_info'] }</p>
                </div>
                <div className="drop_style">
                    <a className="home_drop_down" href="#Project"><MdKeyboardArrowDown size={50}/></a>
                </div>
            </div>
            <div className="right_banner">
                <img className="homeimg_prop" src={home_img} alt=""/>
            </div>
        </div>
        <div className="scroll_down_button">
        <a href="#Project">
        <img src={scroll} alt=""/>
        </a>
        </div>
        <div id="Project">
            <div className="proj_card">
                <div className="left_proj"></div>
                <div className="right_proj">
                    <img className="proj_img_prop" src={HPVC} alt=""/>
                    <span className="proj_detail">
                    <p className="proj_head">{maindata['HPVC']['head']}</p>
                    <p className="proj_des">{maindata['HPVC']['about'].substring(0, 200)}...</p>
                    <NavLink to="/participation/HPVC">
                        <button className="read_more_button">READ MORE</button>
                    </NavLink>
                    </span>
                </div>
            </div>
            <div className="proj_card">
                <div className="left_proj"></div>
                <div className="right_proj">
                    <img className="proj_img_prop" src={AUV} alt=""/>
                    <span className="proj_detail">
                    <p className="proj_head">{maindata['SAUVC']['head']}</p>
                    <p className="proj_des">{maindata['SAUVC']['about'].substring(0, 200)}...</p>
                    <NavLink to="/participation/SAUVC">
                        <button className="read_more_button">READ MORE</button>
                    </NavLink>
                    </span>
                </div>
            </div>
            {/* <div className="proj_card">
                <div className="left_proj"></div>
                <div className="right_proj">
                    <img className="proj_img_prop" src={HPVC} alt=""/>
                    <span className="proj_detail">
                    <p className="proj_head">AUV Line Follower</p>
                    <p className="proj_des">{maindata['AUV_Line_Follower'].substring(0, 200)}...</p>
                    <button className="read_more_button">READ MORE</button>
                    </span>
                </div>
            </div> */}
        </div>
        </React.Fragment>
    )
}

export default Events