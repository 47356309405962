import React from 'react'
import '../../Events/events.css';
import './TCTD.css'
import gears from '../../../assets/icons/gears.svg';
import { maindata } from '../../../maindata';
import tctd from '../../../assets/images/TCTD.png';

function TCTD() {
    return (
        <React.Fragment>
        <div className="about_style">
            <h1>TCTD</h1>

            <img className="event_gear_prop TCTD" src={gears} />

            <p className="main_content">{ maindata['TCTD']['about'] }</p>

            <h2>{ maindata['TCTD']['head'] }</h2>
            <div className="event_content">
            <div className="left_content">
            <p className="main_content">{ maindata['TCTD']['2018_info'] }</p>
            <div className="card">
            <p className="card_head">Main Idea/ Concept</p>
            <p className="card_content">{ maindata['TCTD']['2018_concept'] }</p>
            </div>
            <p className="main_content">{ maindata['TCTD']['2018_info_2'] }</p>
            <p className="main_content">{ maindata['TCTD']['2018_info_3'] }</p>
            <p className="main_content">{ maindata['TCTD']['2018_info_4'] }</p>
            </div>
            <div className="right_content">
                <img class="sample_prop tctd_2018" src={tctd} alt=""/>
            </div>
            </div><br />
        </div>
        </React.Fragment>
    )
}

export default TCTD