import React from 'react'
import './resources.css'
import gears from '../../assets/icons/gears.svg';
import compendium from '../../assets/images/compendium.png'
import studyMaterial from '../../assets/images/studyMaterial.png'
function Resources() {
  return (
    <>

{/**/}


        <div className="about_style">
            <h1>Resources</h1>
            <img className="gear_prop resources" src={gears} />
        </div>

        <div className='containerInside'>
          <div className='resourceImage'><img src={compendium} className="compendium" alt="compendium"></img></div>
          <div className='resourceInfo'>
              <div className='resourceHeading'>ANNUAL COMPENDIUM</div>
              <div className='resourceDescription'>
              The ASME IIT Roorkee Student Section is an undergraduate club
              with students majoring in various engineering fields. Our
              mission is to promote and enhance the technical competency
              and professional well-being of our members through quality
              programs and activities in engineering.</div>
              <div className='resourceDownload'><button class="btn"><a href="https://drive.google.com/uc?id=1o4le_EYMR-UCf9YjlBE_EoVyQNX1hRl3&export=download" style={{textDecoration:"none", color:"#00B2FF"}}>Download</a> </button></div>
          </div>
        

        </div>
        <div className='containerInside'>
            <div className='resourceImage'><img src={studyMaterial} className="studyMaterial" alt="study material"></img></div>
            <div className='resourceInfo'>
              <div className='resourceHeading'>STUDY MATERIAL</div>
              <div className='resourceDescription'>
              This guide is a culmination of basic knowledge required for
              understanding robotics. If you are a robotics enthusiast with a
              willingness to learn, this will prove a valuable resource for
              stepping into the field of robotics. This guide will give you a
              concrete grasp of robotics, including different types of robots and
              their different components.
              </div>
              <div className='resourceDownload'><button class="btn"><a href="https://drive.google.com/uc?id=1_B5D4wkJPp6u1pY1L9mrxGG12r3NbGvm&export=download" style={{textDecoration:"none",color:"#00B2FF"}}>Download</a></button></div>

            </div>
        </div>
  



    </>
  )
}

export default Resources;