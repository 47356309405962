import { useState } from "react";
import classes from "./ContactUs.module.css";
import gears from "../../assets/icons/gears.svg";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
const ContactUs = () => {
  const [mail, SetMail] = useState({
    fname: "",
    email: "",
    subject: "",
    message: "",
  });
  let emailURL =
    "https://mail.google.com/mail/u/0/?fs=1&to=asme@iitr.ac.in&su=";
  function sendMail(e) {
    e.preventDefault();
    emailURL =
      emailURL +
      mail.subject +
      "&body=" +
      mail.message +
      "%0DMessage%20from%20" +
      mail.fname +
      "%0DFrom%20" +
      mail.email +
      "&tf=cm";
    window.open(emailURL, "_blank");
    emailURL = "https://mail.google.com/mail/u/0/?fs=1&to=asme@iitr.ac.in&su=";
  }
  function handle(e) {
    const newMail = { ...mail };
    newMail[e.target.id] = e.target.value;
    SetMail(newMail);
  }
  return (
    <div className="about_style">
      <h1>Contact Us</h1>
      <img
        className={`gear_prop ${classes.contactUs_gear}`}
        src={gears}
        alt="gears"
      />
      <div className={classes.details}>
        <div className={classes.msg}>
          <form className={classes.form} onSubmit={(e) => sendMail(e)}>
            <div className={classes.nameAndEmail}>
              <input
                onChange={(e) => handle(e)}
                type={"text"}
                id="fname"
                name="fname"
                placeholder="Your Name"
                required
              />

              <input
                onChange={(e) => handle(e)}
                type="email"
                id="email"
                name="email"
                placeholder="Your Email-ID"
                required
              />
            </div>
            <div className={classes.subAndMsg}>
              <input
                onChange={(e) => handle(e)}
                type="text"
                id="subject"
                name="subject"
                placeholder="Subject"
                required
              ></input>
              <textarea
                onChange={(e) => handle(e)}
                name="message"
                id="message"
                placeholder="Message"
                rows={3}
                required
              ></textarea>
            </div>
            <div className={classes.buttons}>
              <input type="reset" value="Clear" />
              <input type="submit" value="Send Message" />
            </div>
          </form>
          <div className={classes.asmeContact_details}>
            <div className={classes.number}>
              <BsFillTelephoneFill className={classes.icons} />
              <div>
                <p>Secretary</p>
                <a href="tel:+918092794279">+91-8092794279</a>
              </div>
            </div>
            <div className={classes.email}>
              <IoMail className={classes.icons} />
              <div>
                <p>E-mail</p>
                <a href="mailto:asme@iitr.ac.in">asme@iitr.ac.in</a>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.address}>
          <div className={classes.snapshot}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1536.5301037697593!2d77.89232731835466!3d29.86428883615132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390eb3650bfe37a7%3A0xa9d19b15af050467!2sIndian%20Institute%20Of%20Technology%E2%80%93Roorkee%20(IIT%E2%80%93Roorkee)!5e0!3m2!1sen!2sin!4v1671270434301!5m2!1sen!2sin"
              // width="600"
              // height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className={classes.address_details}>
            <h2>ADDRESS</h2>
            <p>
              Behind Advanced Composites Laboratory, MIED West Block, IIT
              Roorkee, 247667, Roorkee
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
