import React from 'react'
import './eventcard.css'
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Link } from 'react-router-dom';

function Eventcard({event_img, event_name, event_date, event_des, name}) {
    return (
        <div className="main_event_card">
            {
                event_img &&
                <div className="left_event_card">
                    <div className="bottom_img"> </div>
                    <div className="middle_img"> </div>
                    <div className="top_img">
                        <img src={event_img} alt=""/>
                    </div>
                </div>
            }
            <div style={{width:`${event_img?"65%":"100%"}`}} className="right_event_card">
                <div className="event_card_title">{event_name}</div>
                <div className="event_card_date">{event_date}</div>
                <div className="event_card_des">{event_des}</div>
                <div className="event_card_more">
                    {
                        (event_img && name) &&
                        <Link to={`/`+name}>
                            <MdKeyboardArrowDown />
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
}

export default Eventcard
